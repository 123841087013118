import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "activeBackground" ]

  connect() {
  }

  closeModals () {
    if(this.navigationBarController != null) {
      this.navigationBarController.closeModals();
    }
    if(this.tandemFiltersController != null) {
      this.tandemFiltersController.closeModals();
    }
    this.hideActiveBackground();
  }

  showActiveBackground() {
    // Make sure the page is without modals
    this.closeModals();

    const bg = this.activeBackgroundTarget;
    bg.style.display = 'block';
  }

  hideActiveBackground() {
    const bg = this.activeBackgroundTarget;
    bg.style.display = 'none';
  }

  get navigationBarController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("navigation-bar"),
      "navigation-bar"
    );
  }

  get tandemFiltersController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById("tandem-filters"),
      "tandem-filters"
    );
  }
}