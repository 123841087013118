import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "locationPickerButton",
                     "locationPickerStatus",
                     "locationPicker",
                     "locationPickerInput",
                     "learningPickerButton",
                     "learningPickerStatus",
                     "learningPicker",
                     "learningPickerSelect",
                     "learningPickerFilter",
                     "learningPickerOptions",
                     "speakingPickerButton",
                     "speakingPickerStatus",
                     "speakingPicker",
                     "speakingPickerSelect",
                     "speakingPickerFilter",
                     "speakingPickerOptions",
                     "activityPickerButton",
                     "activityPickerStatus",
                     "activityPicker",
                     "activityPickerSelect",
                     "activityPickerFilter",
                     "activityPickerOptions" ]

  connect() {
    this.locationChanged();
    this.learningLanguagesChanged();
    this.speakingLanguagesChanged();
    this.activityChanged();
  }

  // Location picker

  openLocationPicker() {
    const button = this.locationPickerButtonTarget;
    const picker = this.locationPickerTarget;

    this.closeModals();
    this.applicationController.showActiveBackground();

    picker.style.display = 'block';
    button.classList.add("clicked");
  }

  closeLocationPicker() {
    const button = this.locationPickerButtonTarget;
    const picker = this.locationPickerTarget;

    this.applicationController.hideActiveBackground();
    picker.style.display = 'none';
    button.classList.remove("clicked");
  }

  toggleLocationPicker() {
    const picker = this.locationPickerTarget;

    if (window.getComputedStyle(picker).display === 'block') {
      this.closeLocationPicker();
    } else {
      this.openLocationPicker();
    }
  }

  locationChanged() {
    const status = this.locationPickerStatusTarget;
    const input = this.locationPickerInputTarget;

    status.textContent = input.value.trunc(18);

    if(status.textContent === "") {
      status.textContent = "Pick...";
    }
  }

  // Learning languages picker

  openLearningPicker() {
    const button = this.learningPickerButtonTarget;
    const picker = this.learningPickerTarget;
    const select = this.learningPickerSelectTarget;
    const options = this.learningPickerOptionsTarget;

    this.closeModals();
    this.applicationController.showActiveBackground();

    picker.style.display = 'block';
    button.classList.add("clicked");

    // Put options in place
    this.buildSelectOptions(select, options, "pickLearningLanguage");
    this.filterLearningLanguages();
  }

  pickLearningLanguage(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const select = this.learningPickerSelectTarget;
    const link = event.target.parentElement;
    const optionId = link.id;

    for (var i = 0; i < select.options.length; i = i + 1) {
      if(select.options[i].value.toString() == optionId.toString()) {
        if(select.options[i].selected == true) {
          select.options[i].selected = false;
          link.classList.remove("selected");
        } else {
          select.options[i].selected = true;
          link.classList.add("selected");
        }
      }
    }

    this.learningLanguagesChanged();
  }

  closeLearningPicker() {
    const button = this.learningPickerButtonTarget;
    const picker = this.learningPickerTarget;

    this.applicationController.hideActiveBackground();
    picker.style.display = 'none';
    button.classList.remove("clicked");

    // Destroy options
    const options = this.learningPickerOptionsTarget;
    options.innerHTML = "";
  }

  toggleLearningPicker() {
    const picker = this.learningPickerTarget;

    if (window.getComputedStyle(picker).display === 'block') {
      this.closeLearningPicker();
    } else {
      this.openLearningPicker();
    }
  }

  learningLanguagesChanged() {
    const select = this.learningPickerSelectTarget;
    const status = this.learningPickerStatusTarget;
    var placeholder = ""

    for (var i = 0; i < select.options.length; i = i + 1) {
      if(select.options[i].selected == true) {
        if(placeholder == "") {
          placeholder = select.options[i].text;
        } else {
          placeholder = placeholder + ", " + select.options[i].text;
        }
      }
    }

    status.textContent = placeholder.trunc(18);

    if(status.textContent === "") {
      status.textContent = "Pick...";
    }
  }

  filterLearningLanguages() {
    const input = this.learningPickerFilterTarget;
    const inputText = input.value.toLowerCase();
    const options = this.learningPickerOptionsTarget;

    for (var i = 0; i < options.children.length; i = i + 1) {
        const link = options.children[i];

        if (link.innerHTML.toLowerCase().indexOf(inputText) >= 0) {
          link.style.display = 'block';;
        } else {
          link.style.display = 'none';;
        }
      }
  }

  // Speaking languages picker

  openSpeakingPicker() {
    const button = this.speakingPickerButtonTarget;
    const picker = this.speakingPickerTarget;
    const select = this.speakingPickerSelectTarget;
    const options = this.speakingPickerOptionsTarget;

    this.closeModals();
    this.applicationController.showActiveBackground();

    picker.style.display = 'block';
    button.classList.add("clicked");

    // Put options in place
    this.buildSelectOptions(select, options, "pickSpeakingLanguage");
    this.filterSpeakingLanguages();
  }

  pickSpeakingLanguage(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const select = this.speakingPickerSelectTarget;
    const link = event.target.parentElement;
    const optionId = link.id;

    for (var i = 0; i < select.options.length; i = i + 1) {
      if(select.options[i].value.toString() == optionId.toString()) {
        if(select.options[i].selected == true) {
          select.options[i].selected = false;
          link.classList.remove("selected");
        } else {
          select.options[i].selected = true;
          link.classList.add("selected");
        }
      }
    }

    this.speakingLanguagesChanged();
  }

  closeSpeakingPicker() {
    const button = this.speakingPickerButtonTarget;
    const picker = this.speakingPickerTarget;

    this.applicationController.hideActiveBackground();
    picker.style.display = 'none';
    button.classList.remove("clicked");

    // Destroy options
    const options = this.speakingPickerOptionsTarget;
    options.innerHTML = "";
  }

  toggleSpeakingPicker() {
    const picker = this.speakingPickerTarget;

    if (window.getComputedStyle(picker).display === 'block') {
      this.closeSpeakingPicker();
    } else {
      this.openSpeakingPicker();
    }
  }

  speakingLanguagesChanged() {
    const select = this.speakingPickerSelectTarget;
    const status = this.speakingPickerStatusTarget;
    var placeholder = ""

    for (var i = 0; i < select.options.length; i = i + 1) {
      if(select.options[i].selected == true) {
        if(placeholder == "") {
          placeholder = select.options[i].text;
        } else {
          placeholder = placeholder + ", " + select.options[i].text;
        }
      }
    }

    status.textContent = placeholder.trunc(18);

    if(status.textContent === "") {
      status.textContent = "Pick...";
    }
  }

  filterSpeakingLanguages() {
    const input = this.speakingPickerFilterTarget;
    const inputText = input.value.toLowerCase();
    const options = this.speakingPickerOptionsTarget;

    for (var i = 0; i < options.children.length; i = i + 1) {
        const link = options.children[i];

        if (link.innerHTML.toLowerCase().indexOf(inputText) >= 0) {
          link.style.display = 'block';;
        } else {
          link.style.display = 'none';;
        }
      }
  }


  // Activity picker

  openActivityPicker() {
    const button = this.activityPickerButtonTarget;
    const picker = this.activityPickerTarget;
    const select = this.activityPickerSelectTarget;
    const options = this.activityPickerOptionsTarget;

    this.closeModals();
    this.applicationController.showActiveBackground();

    picker.style.display = 'block';
    button.classList.add("clicked");

    // Put options in place
    this.buildSelectOptions(select, options, "pickActivity");
    this.filterActivity();
  }

  pickActivity(event) {
    event.preventDefault();
    event.stopImmediatePropagation();

    const select = this.activityPickerSelectTarget;
    const link = event.target.parentElement;
    const optionId = link.id;

    for (var i = 0; i < select.options.length; i = i + 1) {
      if(select.options[i].value.toString() == optionId.toString()) {
        if(select.options[i].selected == true) {
          select.options[i].selected = false;
          link.classList.remove("selected");
        } else {
          select.options[i].selected = true;
          link.classList.add("selected");
        }
      }
    }

    this.activityChanged();
  }

  closeActivityPicker() {
    const button = this.activityPickerButtonTarget;
    const picker = this.activityPickerTarget;

    this.applicationController.hideActiveBackground();
    picker.style.display = 'none';
    button.classList.remove("clicked");

    // Destroy options
    const options = this.activityPickerOptionsTarget;
    options.innerHTML = "";
  }

  toggleActivityPicker() {
    const picker = this.activityPickerTarget;

    if (window.getComputedStyle(picker).display === 'block') {
      this.closeActivityPicker();
    } else {
      this.openActivityPicker();
    }
  }

  activityChanged() {
    const select = this.activityPickerSelectTarget;
    const status = this.activityPickerStatusTarget;
    var placeholder = ""

    for (var i = 0; i < select.options.length; i = i + 1) {
      if(select.options[i].selected == true) {
        if(placeholder == "") {
          placeholder = select.options[i].text;
        } else {
          placeholder = placeholder + ", " + select.options[i].text;
        }
      }
    }

    status.textContent = placeholder.trunc(18);

    if(status.textContent === "") {
      status.textContent = "Pick...";
    }
  }

  filterActivity() {
    const input = this.activityPickerFilterTarget;
    const inputText = input.value.toLowerCase();
    const options = this.activityPickerOptionsTarget;

    for (var i = 0; i < options.children.length; i = i + 1) {
        const link = options.children[i];

        if (link.innerHTML.toLowerCase().indexOf(inputText) >= 0) {
          link.style.display = 'block';;
        } else {
          link.style.display = 'none';;
        }
      }
  }

  // General

  buildSelectOptions(select, options, actionName) {
    for (var i = 0; i < select.options.length; i = i + 1) {
      this.addSelectOption(select, options, select.options[i], actionName);
    }
  }

  addSelectOption(select, options, option, actionName) {
    const el = document.createElement("a");
    const id = option.value;

    el.dataset.action = "click->tandem-filters#" + actionName;
    el.id = id
    el.innerHTML = "<span>" + option.text + "<span>";

    // Initial state of the link based on the option
    if(option.selected == true) {
      el.classList.add("selected");
    }

    options.appendChild(el);
  }

  // Close all opened modals
  closeModals() {
    this.closeLocationPicker();
    this.closeLearningPicker();
    this.closeSpeakingPicker();
    this.closeActivityPicker();
  }

  get applicationController() {
    return this.application.getControllerForElementAndIdentifier(
      document.body,
      "application"
    );
  }
}