/* eslint no-console:0 */

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("./controllers", true, /\.js$/)
application.load(definitionsFromContext(context))


import "@rails/actiontext"

//import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
//import "channels"

//Rails.start()
ActiveStorage.start()

// import Croppie from 'croppie'
// window.Croppie = Croppie
//require("jquery")
require("@rails/ujs").start()
//require("turbolinks").start()

// require("jquery");
// require("select2").start();
// require("croppie");

//works for select2, not croppie
//import $ from 'jquery'
//import 'select2'

require("jquery");
import "croppie";

// from javascripts/application.js

//const legacyContext = require.context("./legacy", true, /\.js$/)
//application.load(definitionsFromContext(legacyContext))

console.log("after loading files.");

// Legacy
$(document).ready(function () {
  console.log("loaded turbolinks!!!")
});

$(document).ready(function () {
  var el = document.getElementById('sp_prot');
  if(el) {
    el.value = 'notspam';
  };
  el = document.getElementById('sp-prot-container');
  if(el) {
    el.style.display = 'none';
  };

  el = $(".delete")
  if(el) {
    $(".delete").on("click", function() {
      $(".notification").hide();
    });
  }
});

Element.prototype.hasClass = function(className) {
    return this.className && new RegExp("(^|\\s)" + className + "(\\s|$)").test(this.className);
};

String.prototype.trunc = String.prototype.trunc ||
      function(n){
          return (this.length > n) ? this.substr(0, n-1) + '...' : this;
      };


//TODO: fix select2
$(document).ready(function () {
   //$(".needs-select2").select2();
   //$("#study_language_ids").select2();
   //$("#activity_ids").select2();
});

// end legacy

var $uploadCrop = null;

$(document).ready(function () {
  console.log("on turbolinks load...")

  if($("#avatar").length) {

      $uploadCrop = $("#avatar").croppie({
          enableExif: true,
          viewport: {
              width: 160,
              height: 160,
              type: 'circle'
          },
          boundary: {
              width: 160,
              height: 160
          }
      });
      if ($("#current-avatar").attr("src")) {
      $uploadCrop.croppie('bind', {
        url: $("#current-avatar").attr("src")
      });
    }


    $('#avatar-upload').on('change', function () {
      readFile(this); });

    $('#avatar-uploader').on('click', function(e) {
      e.preventDefault();

      $('#avatar-uploader').attr('value', 'Uploading...');

      $uploadCrop.croppie("result", {type: 'blob', format: 'png', size: { width: 160, height: 160 }}).then(function(blob) {

        var data = new FormData();
        data.append('user[photo]', blob);

        $.ajax({
          type: "POST",
          url: "/users/update_avatar",
          data: data,
          processData: false,
          contentType: false,
          success: function(repsonse){
            $('#avatar-uploader').attr('value', 'Upload');
          },
          error: function(response){
            $('#avatar-uploader').attr('value', 'Upload');
            alert("There was an error uploading the avatar. Files bigger than 250Kb are not allowed.")
          }
        })
      });
    })
  }
});

$(document).ready(function () {
  var el = $("#terms-confirmation")
  if(el) {
    $("#terms_agreement").on("click", function(e) {
      if($('#terms_agreement').prop('checked') == true) {
        $("#terms_agreement").prop('checked', false);
      } else {
        $("#terms_agreement").prop('checked', true);
      }
    });
    $("#terms_agreement_label").on("click", function(e) {
      if($('#terms_agreement').prop('checked') == true) {
        $("#terms_agreement").prop('checked', false);
      } else {
        $("#terms_agreement").prop('checked', true);
      }
    });
    $("#terms-confirmation").on("click", function() {
      if($('#terms_agreement').prop('checked') == true) {
        $("#terms_agreement").prop('checked', false);
      } else {
        $("#terms_agreement").prop('checked', true);
      }
    });
  }
});

function readFile(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {

      $uploadCrop = $('#avatar')

      $uploadCrop.addClass('ready');
        $uploadCrop.croppie('bind', {
            url: e.target.result
          }).then(function(){

            //$uploadCrop.result('blob').then(function(blob) {
            //   console.log('bind complete, updating result')
            //});
          });
        }

    reader.readAsDataURL(input.files[0]);
  } else {
    alert("You're browser doesn't support the FileReader API");
  }
}

