import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "toggleButton", "navigation" ]

  connect() {
  }

  openNagivation() {
    const navigation = this.navigationTarget;

    this.applicationController.showActiveBackground();

    navigation.style.display = 'block';
  }

  closeNavigation() {
    const navigation = this.navigationTarget;

    this.applicationController.hideActiveBackground();

    navigation.style.display = 'none';

  }

  toggleNavigation(event) {
    event.preventDefault();

    const navigation = this.navigationTarget;

    if (window.getComputedStyle(navigation).display === 'block') {
      this.closeNavigation();
    } else {
      this.openNagivation();
    }
  }

  closeModals () {
    this.closeNavigation();
  }

  get applicationController() {
    return this.application.getControllerForElementAndIdentifier(
      document.body,
      "application"
    );
  }
}